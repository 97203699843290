import React from "react"

import Layout from "../components/layout";
import { Link } from "gatsby";
import Meta from "../components/meta"
import { Container } from "react-bootstrap"

export default () => (
  <Layout>
    <Meta title={"Seite nicht gefunden"} noindex/>
    <Container className={"section"}>
      <h1>Nicht gefunden</h1>
      <p>
        Hoppla, die angegebene Seite konnte nicht gefunden werden.<br/>
        Möglicherweise wurde sie verschoben oder existiert nicht mehr.
      </p>
      <p>
        <Link to={"/"}>Zurück zur Startseite</Link>
      </p>
    </Container>
  </Layout>
);
